@import "../lib/variables.module";

.Layout__wrapper {
  min-height: calc(100vh - 90px);
  padding: 16px;
  overflow-y: auto;
}

.Layout__wrapper-candidate {
  background-color: $blue__area;
}

.Layout__wrapper-company {
  background-color: $blue__markup;  
}
