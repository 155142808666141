.categorias__wrapper {
  background: #41c0f0;
	width: 100%;
	height: 100%;
}
.page__title {
	color: #fff;
	padding: 20px;
	font-size: 3rem;
}
.categories__selector {
	display: none;
}

.examCards__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 32px;
}
.examCard__wrapper {
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 15px 30px #00000029;
	border-radius: 20px;
	background: #fff;
}
.examCard__wallpaper {
	border-radius: 20px;
	background: #02243F;
	overflow: hidden;
	text-align: center;

	.examCard__wallpaperImage {
    height: 250px;

		img {
			height: 100%;
		}
	}

}

.examCard__title {
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	color: #fff;
	background: #41c0f0;
}
.examCard__text {
	margin: 0px 10px 0px 10px;
	padding: 20px;
	font-size: 1.3rem;
	font-weight: 500;
}
.examCard__points {
	margin: -10px 10px 0px 10px;
	padding: 0px 0px 0px 20px;
	font-size: 1.2rem;
	font-weight: 700;
	color: #808080;
}
.btn__wrapper {
	display: flex;
	justify-content: center;
	align-self: center;
}
.btn__linkTag {
	text-decoration: none;
}

.btn__white {
	width: calc(60vw - 20px);
	height: 50px;
	margin: 15px 0px;
	border: 2px solid #41c0f0;
	border-radius: 10px;
	color: #41c0f0;
	background-color: #fff;
} .btn__white:hover {
	background-color: #41c0f0;
	color: #fff;
}

@media only screen and (min-width: 800px) {
	.topMenu {
		margin: 40px 30px;
		padding: 20px 0;
		display: flex;
		justify-content: space-between;
		}

	.examCards__container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 50px;
		@media (width: 1024px) and (height: 1366px) {
			overflow: auto;
			width: 100%;
			height: 530px;
			grid-template-columns: 1fr 1fr;
			column-gap: 40px;
			margin: 0;
			padding: 10px;
		}.examCards__container::-webkit-scrollbar {
			width: 6px;
		} .examCards__container::-webkit-scrollbar-button {
			display: flex;
		} .examCards__container::-webkit-scrollbar-thumb {
			background-color: #a7a7a7;
			border-radius: 20px;
			height: 80px;
		}
	}
	.btn__white {
		width: 300px;
		margin: 20px 15px;
	}
}
