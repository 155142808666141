.success {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.container__left {
  background: #0B2C46;
  display: flex;
  flex-direction: column;
}

.return__wrapper {
  display: flex;
  align-items: center;
  margin: 30px 0px 0px 30px;
  color: #41c0f0;
  font-size: 1.4em;
  cursor: pointer;
}

.picture__leftWrapper {
  height: 100%;
  margin: 10px 0px 10px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: 20px;
  font-size: 2rem;
  text-align: center;

  .chefImg {
    font-size: 80px;
  }
  .text {
    width: 70%;
    margin: 40px 0 0 0;
    font-size: 0.7em;
    padding: 0 0 20px 0;
  }
}

.container__right {
  background: #41c0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__white {
  width: 100%;
  height: 420px;
  margin: 120px 60px;
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent linear-gradient(335deg, #E8F1FA 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #00000029;
  border-radius: 20px;
  opacity: 1; 
}
        
.picture__rightWrapper {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .text {
    margin: 10px 0 0 0;
    color: black;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
  }
}
.totalPoints {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #41c0f0;

  .number {
      font-size: 5em;
  }
  .text {
      font-size: 2.5em;
  }
}

.btn__lightBlue {
  width: 60%;
  margin: 20px;
  padding: 20px;
  display: flex;
  align-self: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  background: #41C0F0 0% 0% no-repeat padding-box;
  font-size: 1.2rem;
}

@media only screen and (min-width: 800px) {
  .success {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .return__wrapper {
    color: #fff;
    padding: 15px 0px 0px 15px;
  }
  .picture__leftWrapper {
    margin-top: 40px;
    flex-direction: column;

    .chefImg {
      font-size: 180px;
    }
    .text {
      font-size: 1em;
    }
  }
  .picture__rightWrapper {
    .text {
        font-size: 2.5em;
    }
  }
}
