.filters__wrapper {
  display: none;
  flex-direction: row;
  width: 60%;
}
.clear_filters {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #747575;
}
.mainHeader {
  display: flex;
  height: auto;
  width: 100%;
  margin-top: 16px;
}
.icons__wrapper {
  display: none;
}
.switcher__wrapper__mobile {
  display: flex;
  flex-direction: row;
  background-color: #41c0f0;
  height: 32px;
  width: 126px;
  align-items: center;
  position: fixed;
  padding: 2px;
  border-radius: 20px;
  transition: all 0.8s ease-in-out 0s;
  -webkit-box-align: center;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.icon__list {
  display: flex;
  color: #fff;
  font-size: 0.8rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.search__wrapper {
  display: flex;
  width: 100%;
  margin-top: -10px;
  @media (width: 768px) and (height: 1024px) {
    height: 8vh;
    align-items: center;
  }
}
.mobile__filters {
  background: #eaf4f7;
  border: 2px solid #fff;
  border-radius: 6px;
  width: 88%;
  height: 50px;
  margin: 10px;
  margin-right: 0;
  outline: none;
  z-index: 2;
  p {
    color: #0b2c56;
    letter-spacing: 2px;
    padding: 0 auto;
    text-align: left;
    font-size: 0.85rem;
    margin: 18px 0 0 10px;
  }
}
.box__filters {
  display: flex;
  height: 60vh;
  overflow-y: auto;
}
.filters__boxes {
  padding: 10px;
}
.resultdivision {
  display: grid;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-areas: "mapsdivision" "listdivision";
  @media (max-width: 320px) {
    grid-template-rows: 52vh;
  }
  @media (min-width: 321px) {
    grid-template-rows: 57vh;
  }
  @media (width: 360px) and (height: 640px) {
    grid-template-rows: 56vh;
  }
  @media (width: 375px) and (height: 667px) {
    grid-template-rows: 62vh;
  }
  @media (width: 375px) and (height: 812px) {
    grid-template-rows: 65vh;
  }
  @media (width: 411px) and (height: 731px) {
    grid-template-rows: 62vh;
  }
  @media (width: 411px) and (height: 823px) {
    grid-template-rows: 65vh;
  }
  @media (width: 414px) and (height: 736px) {
    grid-template-rows: 63vh;
  }
  @media (width: 768px) and (height: 1024px) {
    grid-template-rows: 71vh;
  }
}
.mapsdivision {
  grid-area: mapsdivision;
  padding: 0;
  width: 100%;
  height: 70vh;
  border-radius: 10px;
  overflow: hidden;
}
.listdivision {
  display: flex;
  flex-direction: column;
  grid-area: listdivision;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  grid-gap: 10;
  bottom: 0;
}
.listSelection {
  display: flex;
  justify-content: center;
  height: 100%;
}
.listSelectionCards {
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: 768px) {
    color: #fff !important;
  }
}
.listMobile {
  width: 100%;
  margin: 0;
  justify-content: center;
}

@media (min-width: 1024px) and (height: 1366px) {
  .buscaativa {
    position: relative;
    height: 50%;
    overflow: hidden;
    background: transparent linear-gradient(336deg, #e8f1fa 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 15px 30px #00000029;
    border-radius: 20px;
    opacity: 1;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .filters__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .filters__title {
    display: none;
  }
  .filters__boxes {
    display: none;
  }
  .search__wrapper {
    display: flex;
    width: 100%;
    margin-top: 30px;
  }
  .icons__wrapper {
    display: flex;
    width: 100%;
    margin-right: 60px;
    justify-content: center;
    align-items: center;
    padding: 0 40px 0 20px;
  }

  .switcher__wrapper {
    margin: 0 auto;
    width: 90%;
    height: 40px;
  }
  .icon__list {
    margin-right: 5px;
  }

  .icon__map {
    margin-right: 5px;
  }
  .switcher__mobile {
    display: none;
  }
  .main {
    height: 80vh;
  }
  .mainHeader {
    display: grid;
    grid-template-columns: 3fr 2fr;
    margin-left: 20px;
    margin-right: 40px;
  }
  .mobile__filters {
    display: flex;
  }
  .listdivision {
    width: 31%;
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
    bottom: auto;
    display: flex;
    flex-direction: column;
  }
  .listdivision::-webkit-scrollbar {
    width: 6px;
  }
  .listdivision::-webkit-scrollbar-button {
    display: none;
  }
  .listdivision::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 20px;
  }
  .resultdivision {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    height: 70vh;
  }
  .mapsdivision {
    display: flex;
    margin-left: 10px;
    padding-right: 0;
    width: 100%;
    overflow: hidden;
  }
  .listSelection {
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
  }
  .listSelection::-webkit-scrollbar {
    width: 6px;
  }
  .listSelection::-webkit-scrollbar-button {
    display: flex;
  }
  .listSelection::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 20px;
  }
  .switcher__wrapper__mobile {
    display: none;
  }
}

/* ================ desktop =================== */
@media screen and (min-width: 1025px) {
  .buscaativa {
    position: relative;
    height: 50%;
    overflow: hidden;
    background: transparent linear-gradient(336deg, #e8f1fa 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 15px 30px #00000029;
    border-radius: 20px;
    opacity: 1;
    margin: 0;
  }
  .main {
    height: auto;
  }
  .buscaativa {
    display: grid;
    grid-template-columns: 0.8fr 4.2fr;
  }
  .filters__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .filters__title {
    display: flex;
    font-size: 24px;
    color: #0b2c46;
    margin: 20px 0px 20px 30px;
    font-weight: 400;
  }
  .filters__boxes {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .search__wrapper {
    display: flex;
    width: 100%;
    margin-top: 0px;
  }
  .icons__wrapper {
    display: flex;
    width: 100%;
    margin-right: 60px;
    justify-content: center;
    align-items: center;
    padding: 0 40px 0 20px;
  }
  .switcher__wrapper {
    margin: 0 auto;
    width: 90%;
    height: 40px;
  }
  .icon__list {
    margin-right: 5px;
  }

  .icon__map {
    margin-right: 5px;
  }
  .switcher__mobile {
    display: none;
  }
  .main {
    height: auto;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .mainHeader {
    display: grid;
    grid-template-columns: 3fr 2fr;
    margin-left: 20px;
    margin-right: 40px;
  }
  .mobile__filters {
    display: none;
  }
  .listdivision {
    width: 31%;
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 8px;
    margin-right: 0;
    margin-left: 5px;
    bottom: auto;
    display: flex;
    flex-direction: column;
  }
  .listdivision::-webkit-scrollbar {
    width: 6px;
  }
  .listdivision::-webkit-scrollbar-button {
    display: none;
  }
  .listdivision::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 20px;
  }
  .resultdivision {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 16px;
    width: 100%;
    height: 100vh;
  }
  .mapsdivision {
    display: flex;
    margin: 0 10px;
    padding-right: 0;
    width: 100%;
    overflow: hidden;
  }
  .listSelection {
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
    padding: 24px;
  }
  .listSelection::-webkit-scrollbar {
    width: 6px;
  }
  .listSelection::-webkit-scrollbar-button {
    display: flex;
  }
  .listSelection::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 20px;
    height: 70px;
  }
  .switcher__wrapper__mobile {
    display: none;
  }
}
@media screen and (min-width: 1920px) {
  .buscaativa {
    grid-template-columns: 1.1fr 3.9fr;
  }
}
@media screen and (max-width: 1023px) {
  .resultdivision {
    grid-template-rows: auto;
  }
}
