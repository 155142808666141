.FullCalendar__small-calendar {
  .Calendar {
    background: transparent;
    box-shadow: none;
  }
}

.Event__interview {
  background-color: #e4eff3 !important;
  border-left: 8px solid #41C0F0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;

  .rbc-event-label,
  .rbc-event-content {
    color: #41C0F0 !important;
  }
}

.Event__scheduled-interview {
  background-color: #ffdcbe !important;
  border-left: 8px solid #eb8126 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;

  .rbc-event-label,
  .rbc-event-content {
    color: #eb8126 !important;
  }
}

.Event__freelancer {
  background-color: #e3f1d2 !important;
  border-left: 8px solid #72b920 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;

  .rbc-event-label,
  .rbc-event-content {
    color: #72b920 !important;
  }
}
  
.rbc-event {
  padding: 5px !important;

  .rbc-event-label {
    font-size: 12px;
  }
}
