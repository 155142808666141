.candidate__modal {
  overflow: scroll;
  overflow-x: hidden;
}.candidate__modal::-webkit-scrollbar {
  width: 6px;
} .candidate__modal::-webkit-scrollbar-button {
  display: flex;
} .candidate__modal::-webkit-scrollbar-thumb {
  background-color: #a7a7a7;
  border-radius: 20px;
}
.holder {
  width: 20%;
  height: 2px;
  margin: 0% 40% 0% 40%;
  display: flex;
  justify-content: center;
  color: #a7a7a7;
  border: solid 2px #a7a7a7;
  border-radius: 20px;
  background-color: #a7a7a7;
}
.candidate__header {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px #a7a7a7 solid;
  margin-bottom: 20px;
  flex-direction: column;
}
.icon__arrow {
  display: none;
}
.avatar {
  border: solid 3px #41c0f0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 20px;
} .avatar >img {
  width: 114px;
  height: 113px;
  border-radius: 50%;
}
.candidate__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
} 
.candidate__name {
  font-size: 2rem;
}
.totalPoints, .points__number {
  display: none;
}
.candidate__experience {
  margin-top: 20px;
  width: 100%;
}
.experiences__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.skills__title {
  margin-top: 40px;
  font-size: 1.6rem;
  font-weight: 700;
}
.experiencesInfo__wrapper  {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #a7a7a7;
  font-size: 1.2rem;
}
.experience__align {
  text-align: start;
}
.skillsInfo__wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.2rem;
}
.skills {
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 0px 0px;
  color: #0B2C46;
}

@media screen and (min-width: 800px) {
  .candidate__modal {
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .candidate__modal::-webkit-scrollbar {
    width: 6px;
  }
  
  .candidate__modal::-webkit-scrollbar-button {
    display: flex;
  }
  
  .candidate__modal::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 20px;
  }
  .holder {
    display: none;
  }

  .candidate__header {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr 4fr;
    justify-items: center;
    align-items: center;
      .icon__arrow {
        display: flex;
        cursor: pointer;
      }
      .avatar > img {
        width: 113px;
        height: 114px;
        border-radius: 50%;
      }
      .points__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      .totalPoints {
        display: block;
        color: #a7a7a7;
        font-size: 1rem;
        letter-spacing: 2.1px;
      }
      .points__number {
        display: block;
        padding-top: 10px;
        color: #41c0f0;
        font-size: 2rem;
      }
  }


  .candidate__experience {
    width: 80%;
    .experiencesInfo__wrapper {
      position: relative;
      bottom: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: stretch;
      margin: 20px 0px 20px 0px;
      padding-right: 40px;
      font-size: 1.4rem;
      color: #a7a7a7;
    } 
    .experience__align {
      text-align: end;
    }
    .skills {
      width: 100%;
    }
  }
}

@media (max-width: 799px) {
  .icon__arrow {
    position: absolute;
    top: 8px;
    display: block;
    left: 65px;
  }
}

@media (max-width: 450px) {
  .icon__arrow {
    position: absolute;
    top: 47px;
    display: block;
    left: 65px;
  }
}