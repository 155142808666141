.CardBig {
  margin: 16px;
}
.Avatar {
  display: flex;
  justify-content: center;
}
.avatar__wrapper {
  width: 90px;
  height: 90px;
  margin-bottom: -40px; 
  display: flex;
  border-radius: 50%;
  border: 2px solid #41C0F0;
} 
.avatar__wrapper > * {
  max-width: 100%;
  border-radius: 50%;
  
}
.content {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 15px 30px #00000029;
}
.content__top {
  display: grid;
  height: 20px;
  grid-template-columns: 1fr 1fr;
}
.seeDistance, .favorite {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  height: 10px;
}
.favorite {
  align-content: flex-end;
  margin-left: 80%;
} 
.message {
  bottom: 8px;
  visibility: hidden;
  width: auto;
  height: auto;
  padding: 3px;
  background-color: #00000069;
  color: #fff;
  font-size: .8rem;
  text-align: right;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.seeDistance:hover .message {
  visibility: visible;
}
.favorite:hover .message {
  visibility: visible;
}
.content__top {
  display: grid;
  height: 20px;
  grid-template-columns: 1fr 1fr;
}
.seeDistance, .favorite {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  height: 10px;
}
.favorite {
  align-content: flex-end;
  margin-left: 80%;
} 
.message {
  bottom: 8px;
  visibility: hidden;
  width: auto;
  height: auto;
  padding: 3px;
  background-color: #00000069;
  color: #fff;
  font-size: .8rem;
  text-align: right;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.seeDistance:hover .message {
  visibility: visible;
}
.favorite:hover .message {
  visibility: visible;
}
.top__wrapper {
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
}
.candidate__name {
  font-size: 1.5em;
}
.totalPoints {
  display: block;
  color: #707070;
  padding-top: 5px;  
}
.candidate__points {
  color: #41C0F0;
  font-size: 1.6em;
  padding-top: 5px;  
}

.bottom__wrapper {
  display: flex;
  flex-direction: column;
  color: #909090;
  font-size: 1.2em;
}
.candidate__position {
  margin-top: 30px;
  height: 40px;
}
.lastExperience__title {
  margin-top: 20px;
}
.distance__title {
  margin-top: 20px;
}

.agendar__btn {
  height: 60px;
  margin-top: 20px;
  border: 2px solid #2AD38B;
  border-radius: 10px;
  font-size: 1.4rem;
  color: #fff;
  background-color: #2AD38B;
}
.agendar__btn:hover {
  background-color: #fff;
  color: #2AD38B;
}

@media screen and (min-width: 900px){
  .agendar__btn {
    color: #2AD38B;
    background-color: #fff;
  }
  .agendar__btn:hover {
    background-color: #2AD38B;
    color: #fff;
  }
  .distance {
    font-size: 1.2rem;
  }
  .distance__title {
    font-size: 1.2rem;
  }
  
}
