.Header {
  height: 90px;
  display: flex;
  align-items: center;
  padding: 24px;

  .Header__logo {
    padding: 0 16px;
  }

  .Header__dropbtn {
    border: none;
    cursor: pointer;
  }

  .Header__dropdown {
    position: relative;
    display: inline-block;
  }

  .Header__dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9999;
    top: 36px;
    border-radius: 8px;
    padding: 2px;
  }

  .Header__dropdown-content > a {
    color: $gray__line !important;
    padding: 16px;
    text-decoration: none;
    display: block;
  }

  .Header__dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .Header__dropdown:hover .Header__dropdown-content {
    display: block;
  }
}

.Header__company {
  background-color: $blue__markup;

  ul li a {
    text-decoration: none;
    color: $gray__default;
  }

  .Header__link-active {
    border-bottom: 2px solid $blue__area;
    color: $blue__area;

    a {
      color: $blue__area;
    }
  }

  .Header__profile-options {
    img {
      border: 2px solid $blue__area;
    }

    .Header__profile-name {
      color: $gray__default;
    }

    .Header__profile-name--active {
      color: $blue__area;
    }
  }
}

.Header__candidate {
  background-color: $blue__area;

  ul li a {
    text-decoration: none;
    color: $white__default;
  }

  .Header__link-active {
    border-bottom: 2px solid $white__default;
    color: $white__default;
    font-weight: bold;

    a {
      color: $white__default;
    }
  }

  .Header__profile-options {
    img {
      border: 2px solid $green;
    }
    .Header__profile-name {
      color: $white__default;
    }
  }
}

@media screen and (min-width: 1920px){
  .Header {
    padding: 0;
  }
}