$blue__markup   : #0b2c46;
$blue__area     : #41C0F0;
$blue__effect   : #def6ff;

$green          : #3BFBAA;

$white__default : #fff;
$black__default : #000;

$gray__light    : #e3e3e3;
$gray__default  : #8596a3;
$gray__line     : #707070;
$gray__markup   : #F2F2F2;
$gray__tip      : #848484;

$red            : #F25858;

$breakpoint-mobile: 600px;
$breakpoint-desktop: 960px;
$breakpoint-desktop-lg: 1280px;