.top__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    margin: 20px 0 40px 5px;
    border: none;
}
.header__item {
    display: flex;
    align-items: center;
    margin: 20px;
}
.addVacancy__btnWrapper {
    color: #fff;
    border: none;
    background-color: transparent;
    font-size: 1.2em;
}
.addVacancy__iconWrapper {
    width: 60px;
    height: 60px;
    background-color: #41c0f0;
    border-radius: 50%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
}
.vacancy__wrapper {
    margin: 20px 0px 0px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 15px 30px #00000029;
    background-color: #fff;
    border-radius: 15px;
}
.wrapper__left {
    width: 100%;
    margin: 0px 0px 30px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.vacancy__info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    width: 100%;
}
.vacancy__id {
    margin-top: 10px;
    color: #41c0f0;
    font-weight: 700;
}
.vacancy__title {
    font-size: 1.6em;
    font-weight: 600;
}
.vacancy__description {
    margin-top: 15px;
}
.vacancy__points {
    margin-top: 15px;
}
.vacancy__mobileEdit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 2px 0 2px 0;
    font-size: .8rem;
    color: #41c0f0;
    fill: #41c0f0;
    width: 100%;
    font-weight: 500;
}
.edit {
    display: flex;
}
.edit__down {
    margin: 5px 5px 0 0px;
}
.wrapper__right {
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding: 0px 0px 0px 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.vacancy__candidates {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
}
.vacancy__candidateImages {
    width: 100%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
} 
.vacancy__candidateImages > * {
    padding: 2px;
}
.vacancy__seeAllBtn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: none;
    background-color: #fff;
}
.seeAll__icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #41c0f0;
    border-radius: 50%;
    font-size: 25px;
}
.seeAll__text { 
    white-space: nowrap;
    padding-left: 5px;
}
.vacancy__edit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.vacancy__illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
}
.switcher__wrapper {
    display: flex;
    align-self: center;
    align-items: center;
}
.switchItem {
    padding: 0px 10px 0px 15px;
    margin-top: 30px;
}
.switcher {
    margin-top: 30px;
}
.btn__wrapper {
    display: none;
}  

@media screen and (min-width: 900px) {
    .top__wrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .vacancy__wrapper {
        width: 100%;
        height: 200px;
        padding: 20px;
        display: grid;
        grid-template-columns: 0.7fr 1fr;
        text-align: center;
        align-items: center;
        box-shadow: 0px 15px 30px #00000029;
        background-color: #fff;
        border-radius: 15px;
    }
    .vacancy__mobileEdit {
        display: none;
    }

    .wrapper__right {
        display: grid ;
        grid-template-columns: 1fr 0.7fr;
        align-items: center;
        margin: 0;
    }
    .vacancy__edit {
        flex-direction: column;
        height: 100%;
        align-items: center;
    }
    .switcher__wrapper {
        margin-bottom: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .switchItem {
        font-weight: 400;
        padding: 0px 5px 0px 15px;
        white-space: nowrap;
    }

    .btn__wrapper {
        width: 240px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: #fff;
        background-color: #41c0f0;
        fill: #fff;
        font-weight: 900;
    }
    .btn__text {
        padding-left: 10px;
    }
}
