/* CSS Steps Cadastro */

.steps__wrapper {
  float: left;
  width: 50%;
}
.steps__content {
  border-left: 1px solid #8596a3;
  height: 222px;
  position: relative;
}
.steps__ball {
  width: 10px;
  height: 10px;
  border: 1px solid #8596a3;
  background-color: #8596a3;
  border-radius: 50%;
  position: relative;
  display: block;
  left: -6px;
}
.steps__superBall {
  width: 60px;
  height: 60px;
  border: 1px solid #41C0F0;
  background-color: #0b2c46;
  border-radius: 50%;
  position: absolute;
  display: block;
  left: -31px;
}
.steps__superBallone {
  top: -25px;
}
.steps__superBalltwo {
  top: 70px;
}
.steps__superBallthree {
  top: 188px;
}
.steps__superBallone ~ .steps__ballOne,
.steps__superBalltwo ~ .steps__ballTwo,
.steps__superBallthree ~ .steps__ballThree  {
  background-color: #41C0F0;
  border: 1px solid #41C0F0;
}
.steps__superBallone ~ .steps__titleOne,
.steps__superBalltwo ~ .steps__titleTwo,
.steps__superBallthree ~ .steps__titleThree {
  color: #41C0F0;
  font-size: 20px;
  left: 40px;
}

.steps__ballOne {
  top: 0px;
}
.steps__ballTwo {
  top: 65px;
}
.steps__ballThree {
  top: 150px;
}
.steps__title {
  position: relative;
  left: 17px;
  font-size: 21px;
  color: #8596a3;
}
.steps__titleOne {
  top: -17px;
}
.steps__titleTwo {
  top: 50px;
}
.steps__titleThree {
  top: 130px;
}

/* CSS Steps Provas Técnicas */

.stepsExam__wrapper {
  float: left;
  width: 100%;
  height: 100%;
}
.stepsExam__content {
    width: 100%;
    height: 222px;
    position: relative;
}
.stepsExam__ball {
  width: 10px;
  height: 10px;
  border: 1px solid #8596a3;
  background-color: #8596a3;
  border-radius: 50%;
  position: relative;
  display: block;
  left: -6px;
}
.stepsExam__superBall {
  width: 40px;
  height: 40px;
  border: 1px solid #41C0F0;
  background-color: #0b2c46;
  border-radius: 50%;
  position: absolute;
  display: block;
  left: -21px;
}
.stepsExam__superBallone {
  top: -15px;
}
.stepsExam__superBalltwo {
  top: 35px;
}
.stepsExam__superBallthree {
  top: 85px;
}
.stepsExam__superBallfour {
  top: 135px;
}
.stepsExam__superBallfive {
  top: 185px;
}
.stepsExam__superBallsix {
  top: 235px;
}
.stepsExam__superBallseven {
  top: 285px;
}
.stepsExam__superBalleight {
  top: 335px;
}
.stepsExam__superBallnine {
  top: 385px;
}
.stepsExam__superBallten {
  top: 435px;
}
.stepsExam__superBallone ~ .stepsExam__ballOne,
.stepsExam__superBalltwo ~ .stepsExam__ballTwo,
.stepsExam__superBallthree ~ .stepsExam__ballThree,
.stepsExam__superBallfour ~ .stepsExam__ballFour,
.stepsExam__superBallfive ~ .stepsExam__ballFive,
.stepsExam__superBallsix ~ .stepsExam__ballSix,
.stepsExam__superBallseven ~ .stepsExam__ballSeven,
.stepsExam__superBalleight ~ .stepsExam__ballEight,
.stepsExam__superBallnine ~ .stepsExam__ballNine,
.stepsExam__superBallten ~ .stepsExam__ballTen
  {
  background-color: #41C0F0;
  border: 1px solid #41C0F0;
}
.stepsExam__superBallone ~ .stepsExam__titleOne,
.stepsExam__superBalltwo ~ .stepsExam__titleTwo,
.stepsExam__superBallthree ~ .stepsExam__titleThree,
.stepsExam__superBallfour ~ .stepsExam__titleFour,
.stepsExam__superBallfive ~ .stepsExam__titleFive,
.stepsExam__superBallsix ~ .stepsExam__titleSix,
.stepsExam__superBallseven ~ .stepsExam__titleSeven,
.stepsExam__superBalleight ~ .stepsExam__titleEight,
.stepsExam__superBallnine ~ .stepsExam__titleNine,
.stepsExam__superBallten ~ .stepsExam__titleTen {
  color: #41C0F0;
  font-size: 20px;
  left: 30px;
}

.stepsExam__ballOne {
  top: 0px;
}
.stepsExam__ballTwo {
  top: 20px;
}
.stepsExam__ballThree {
  top: 40px;
}
.stepsExam__ballFour {
  top: 60px;
}
.stepsExam__ballFive {
  top: 80px;
}
.stepsExam__ballSix {
  top: 100px;
}
.stepsExam__ballSeven {
  top: 120px;
}
.stepsExam__ballEight {
  top: 140px;
}
.stepsExam__ballNine {
  top: 160px;
}
.stepsExam__ballTen {
  top: 180px;
}
.stepsExam__title {
  position: relative;
  left: 20px;
  font-size: 20px;
  color: #8596a3;
}
.stepsExam__titleOne {
  top: -15px;
}
.stepsExam__titleTwo {
  top: 5px;
}
.stepsExam__titleThree {
  top: 25px;
}
.stepsExam__titleFour {
  top: 45px;
}
.stepsExam__titleFive {
  top: 65px;
}
.stepsExam__titleSix {
  top: 85px;
}
.stepsExam__titleSeven {
  top: 105px;
}
.stepsExam__titleEight {
  top: 125px;
}
.stepsExam__titleNine {
  top: 145px;
}
.stepsExam__titleTen {
  top: 165px;
}

/* CSS Steps Teste Perfil Profissional */

.stepsProfileTest__wrapper {
  float: left;
  width: 100%;
  height: 100%;
}
.stepsProfileTest__content {
    width: 100%;
    height: 222px;
    position: relative;
}
.stepsProfileTest__ball {
  width: 10px;
  height: 10px;
  border: 1px solid #8596a3;
  background-color: #8596a3;
  border-radius: 50%;
  position: relative;
  display: block;
  left: -6px;
}
.stepsProfileTest__superBall {
  width: 30px;
  height: 30px;
  border: 1px solid #41C0F0;
  background-color: #0b2c46;
  border-radius: 50%;
  position: absolute;
  display: block;
  left: -16px;
}
.stepsProfileTest__superBallone {
  top: -18px;
}
.stepsProfileTest__superBalltwo {
  top: 18px;
}
.stepsProfileTest__superBallthree {
  top: 54px;
}
.stepsProfileTest__superBallfour {
  top: 90px;
}
.stepsProfileTest__superBallfive {
  top: 126px;
}
.stepsProfileTest__superBallsix {
  top: 162px;
}
.stepsProfileTest__superBallseven {
  top: 198px;
}
.stepsProfileTest__superBalleight {
  top: 234px;
}
.stepsProfileTest__superBallnine {
  top: 270px;
}
.stepsProfileTest__superBallten {
  top: 306px;
}
.stepsProfileTest__superBalleleven {
  top: 342px;
}
.stepsProfileTest__superBalltwelve {
  top: 378px;
}
.stepsProfileTest__superBallthirteen {
  top: 414px;
}
.stepsProfileTest__superBallfourteen {
  top: 450px;
}
.stepsProfileTest__superBallfiveteen {
  top: 486px;
}
.stepsProfileTest__superBallsixteen {
  top: 522px;
}
.stepsProfileTest__superBallseventeen {
  top: 558px;
}
.stepsProfileTest__superBalleighteen {
  top: 594px;
}
.stepsProfileTest__superBallnineteen {
  top: 630px;
}
.stepsProfileTest__superBalltwenty {
  top: 666px;
}
.stepsProfileTest__superBallone ~ .stepsProfileTest__ballOne,
.stepsProfileTest__superBalltwo ~ .stepsProfileTest__ballTwo,
.stepsProfileTest__superBallthree ~ .stepsProfileTest__ballThree,
.stepsProfileTest__superBallfour ~ .stepsProfileTest__ballFour,
.stepsProfileTest__superBallfive ~ .stepsProfileTest__ballFive,
.stepsProfileTest__superBallsix ~ .stepsProfileTest__ballSix,
.stepsProfileTest__superBallseven ~ .stepsProfileTest__ballSeven,
.stepsProfileTest__superBalleight ~ .stepsProfileTest__ballEight,
.stepsProfileTest__superBallnine ~ .stepsProfileTest__ballNine,
.stepsProfileTest__superBallten ~ .stepsProfileTest__ballTen,
.stepsProfileTest__superBalleleven ~ .stepsProfileTest__ballEleven,
.stepsProfileTest__superBalltwelve ~ .stepsProfileTest__ballTwelve,
.stepsProfileTest__superBallthirteen ~ .stepsProfileTest__ballThirteen,
.stepsProfileTest__superBallfourteen ~ .stepsProfileTest__ballFourteen,
.stepsProfileTest__superBallfiveteen ~ .stepsProfileTest__ballFiveteen,
.stepsProfileTest__superBallsixteen ~ .stepsProfileTest__ballSixteen,
.stepsProfileTest__superBallseventeen ~ .stepsProfileTest__ballSeventeen,
.stepsProfileTest__superBalleighteen ~ .stepsProfileTest__ballEighteen,
.stepsProfileTest__superBallnineteen ~ .stepsProfileTest__ballNineteen,
.stepsProfileTest__superBalltwenty ~ .stepsProfileTest__ballTwenty

  {
  background-color: #41C0F0;
  border: 1px solid #41C0F0;
}
.stepsProfileTest__superBallone ~ .stepsProfileTest__titleOne,
.stepsProfileTest__superBalltwo ~ .stepsProfileTest__titleTwo,
.stepsProfileTest__superBallthree ~ .stepsProfileTest__titleThree,
.stepsProfileTest__superBallfour ~ .stepsProfileTest__titleFour,
.stepsProfileTest__superBallfive ~ .stepsProfileTest__titleFive,
.stepsProfileTest__superBallsix ~ .stepsProfileTest__titleSix,
.stepsProfileTest__superBallseven ~ .stepsProfileTest__titleSeven,
.stepsProfileTest__superBalleight ~ .stepsProfileTest__titleEight,
.stepsProfileTest__superBallnine ~ .stepsProfileTest__titleNine,
.stepsProfileTest__superBallten ~ .stepsProfileTest__titleTen,
.stepsProfileTest__superBalleleven ~ .stepsProfileTest__titleEleven,
.stepsProfileTest__superBalltwelve ~ .stepsProfileTest__titleTwelve,
.stepsProfileTest__superBallthirteen ~ .stepsProfileTest__titleThirteen,
.stepsProfileTest__superBallfourteen ~ .stepsProfileTest__titleFourteen,
.stepsProfileTest__superBallfiveteen ~ .stepsProfileTest__titleFiveteen,
.stepsProfileTest__superBallsixteen ~ .stepsProfileTest__titleSixteen,
.stepsProfileTest__superBallseventeen ~ .stepsProfileTest__titleSeventeen,
.stepsProfileTest__superBalleighteen ~ .stepsProfileTest__titleEighteen,
.stepsProfileTest__superBallnineteen ~ .stepsProfileTest__titleNineteen,
.stepsProfileTest__superBalltwenty ~ .stepsProfileTest__titleTwenty {
  color: #41C0F0;
  font-size: 16px;
  left: 30px;
}

.stepsProfileTest__ballOne {
  top: -8px;
}
.stepsProfileTest__ballTwo {
  top: 2px;
}
.stepsProfileTest__ballThree {
  top: 12px;
}
.stepsProfileTest__ballFour {
  top: 22px;
}
.stepsProfileTest__ballFive {
  top: 32px;
}
.stepsProfileTest__ballSix {
  top: 42px;
}
.stepsProfileTest__ballSeven {
  top: 52px;
}
.stepsProfileTest__ballEight {
  top: 62px;
}
.stepsProfileTest__ballNine {
  top: 72px;
}
.stepsProfileTest__ballTen {
  top: 82px;
}
.stepsProfileTest__ballEleven {
  top: 92px;
}
.stepsProfileTest__ballTwelve {
  top: 102px;
}
.stepsProfileTest__ballThirteen {
  top: 112px;
}
.stepsProfileTest__ballFourteen {
  top: 122px;
}
.stepsProfileTest__ballFiveteen {
  top: 132px;
}
.stepsProfileTest__ballSixteen {
  top: 142px;
}
.stepsProfileTest__ballSeventeen {
  top: 152px;
}
.stepsProfileTest__ballEighteen {
  top: 162px;
}
.stepsProfileTest__ballNineteen {
  top: 172px;
}
.stepsProfileTest__ballTwenty {
  top: 183px;
}
.stepsProfileTest__title {
  position: relative;
  left: 20px;
  font-size: 16px;
  color: #8596a3;
}
.stepsProfileTest__titleOne {
  top: -20px;
}
.stepsProfileTest__titleTwo {
  top: -10px;
}
.stepsProfileTest__titleThree {
  top: 0px;
}
.stepsProfileTest__titleFour {
  top: 10px;
}
.stepsProfileTest__titleFive {
  top: 20px;
}
.stepsProfileTest__titleSix {
  top: 30px;
}
.stepsProfileTest__titleSeven {
  top: 40px;
}
.stepsProfileTest__titleEight {
  top: 50px;
}
.stepsProfileTest__titleNine {
  top: 60px;
}
.stepsProfileTest__titleTen {
  top: 70px;
}
.stepsProfileTest__titleEleven {
  top: 80px;
}
.stepsProfileTest__titleTwelve {
  top: 90px;
}
.stepsProfileTest__titleThirteen {
  top: 100px;
}
.stepsProfileTest__titleFourteen {
  top: 110px;
}
.stepsProfileTest__titleFiveteen {
  top: 120px;
}
.stepsProfileTest__titleSixteen {
  top: 130px;
}
.stepsProfileTest__titleSeventeen {
  top: 140px;
}
.stepsProfileTest__titleEighteen {
  top: 150px;
}
.stepsProfileTest__titleNineteen {
  top: 160px;
}
.stepsProfileTest__titleTwenty {
  top: 170px;
}

@media (max-width: 960px) {
  .steps__content {
    height: 150px;
    margin-top: 40px;
  }
  .steps__ballTwo {
    top: 40px;
  }
  .steps__superBalltwo {
    top: 45px;
  }
  .steps__titleTwo {
    top: 25px;
  }
  .steps__ballThree {
    top: 80px;
  }
  .steps__superBallthree {
    top: 117px;
  }
  .steps__titleThree {
    top: 63px;
  }
}