.simple__modal {
    position: relative;
    width: 100%;
    height: 60vh;
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 0px 8px 15px #00000029;
}
.modal__header {
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__title {
    font-size: 1.6rem;
}
.header__vacancy {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header__number {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    background-color: #1FE390;
    font-size: 1.3rem;
}
.model__content {
    margin: 20px 0px 0px 0px;
}
.modal__vacancy {
    width: 100%;
    height: 90px;
    margin: 20px 0px 0px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
} :checked {
    background-color: red;
}
.modal__vacancy:hover {
    transition: 1s;
    box-shadow: 0px 8px 15px #00000029;
    background: #ECF4FB;
    opacity: 1;
}
.vacancy__title {
    font-size: 1.6rem;
}
.modal__positions, .modal__points {
    font-size: 1.4rem;
}
.modal__greenBtn {
    width: 100%;
    height: 70px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
    background-color: #1FE390   ;
    color: #fff;
    border: solid 2px #fff;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    } .modal__greenBtn:hover {
    background-color: #09b970;
    color: #fff;
    opacity: 1;
}
.modalNext__btn {
    width: 100%;
    height: 80px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    border: 2px solid #fff;
    background-color: #1FE390;
    font-size: 1.6rem;
    overflow: auto;
    border-radius: 0px 0px 15px 15px;
    outline: none;
}.modalNext__btn:hover {
    background-color: #09b970;
    transition: 1s;
}.modalNext__btn:focus {
    border: #1FE390;
}
@media screen and (min-width: 600px) {
    .simple__modal {
        width: 60%;
        margin: 20px 0 0 20%;
        border-radius: 20px;
        outline-style: none;
        overflow: scroll;
        overflow-x: hidden;
        height: 90vh;
        box-shadow: 0px 15px 30px #00000029;
    }.simple__modal::-webkit-scrollbar {
        width: 6px;
    } .simple__modal::-webkit-scrollbar-button {
        display: flex;
    } .simple__modal::-webkit-scrollbar-thumb {
        background-color: #a7a7a7;
        border-radius: 20px;
    }.simple__modal:hover {
        text-align: unset;
    }
    .modal__greenBtn {
        width: 100%;
        height: 60px;
        margin-top: 10px;
        padding: 10px 20px;
        display: flex;
        position:static;
        justify-content: center;
        align-items: center;
        background-color: #1FE390;
        color: #fff;
        border: solid 2px #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        outline-style: none;
    }
    .modal__greenBtn:hover {
        background-color: #09b970;
        color: #fff;
        outline-style: none;
        opacity: 1;
    }
}