@import '../lib/variables.module';
@import '../lib/buttons.module';

.register__container {
  padding: 0 !important;
  width: 100% !important;
  height: 100vh;
}

.register__box {
  background: #fff;
  min-height: 600px;
  min-width: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  padding: 30px;
  padding-bottom: 200px;
  float: left;
  width: 100%;
  margin-bottom: 60px;
}

.register__wrapperLeft {
  float: left;
  width: 50%;
  background-color: $blue__markup;
  color: $white__default;
  height: calc(100vh - 90px);
  transition: ease-out .100s
}
.register__wrapperLeftCompany {
  @extend .register__wrapperLeft;
  background: none;
  color: inherit;
  padding-right: 40px;
  height: 835px;
}
.register__wrapperRight {
  float: left;
  width: 50%;
  background-color: $white__default;
  height: calc(100vh - 90px);
  padding: 0 30px 80px 30px;
  transition: ease-out .100s;
  overflow-y: auto;

  .register__wrapperRightContent {
    max-width: 720px;
    height: 100%;
    align-items: center;
  }
}

@media (max-width: $breakpoint-desktop) {
  .register__wrapperRightContent {
    max-width: none !important;
  }
}
@media (max-width: $breakpoint-desktop-lg) {
  .register__wrapperLeft {
    height: calc(100vh - 58px);
  }
  .register__wrapperRight {
    height: calc(100vh - 58px);
  }
}

@media (min-width: 1800px) {
  .register__wrapperRightContent {
    display: flex;
  }
}

.register__wrapperRightCompany {
  @extend .register__wrapperRight;
  overflow-y: inherit;
  height: 835px;
}

.register__contentInfo {
  float: right;
  position: relative;
  top: 50%;
  margin-top: -111px;
  width: 100%;
  display: flex;
  max-width: 720px;
}
.register__icon {
  float: left;
  margin-right: 10px;
  margin-top: 37px;
  border-radius: 50%;
  border: 2px solid #41C0F0;
  padding: 15px;
  svg {
    font-size: 30px;
    color: #41C0F0;
  }
}
.register__headerTitle {
  float: left;
  width: 55%;
  padding: 35px 5% 0 5%;
}
.register__header {
  float: left;
  font-weight: 400;
  font-size: 30px;
  margin-top: 40px;
}
.register__steps{
  width: 50%;
}

.register__success {
  width: 96vw;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
  background-color: #0B2C46;
}

.register__successContent {
  margin-bottom: 30px;
}

.register__successContentIcon {
  display: block;
}

.register__successIcon {
  border: 2px solid $blue__area;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  margin-bottom: 20px;
  svg{
    color: $blue__area;
    font-size: 60px;
  }
}

.register__successTitle {
  color: $white__default;
  font-size: 30px;
  line-height: 40px;
}

.register__successBox {
  width: 70%;
  min-height: 150px;
  padding: 30px;
  background-color: $white__default;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media(max-width: 600px) {
    flex-direction: column;
  }
}

.register__successLamp {
  float: left;
  display: flex;
  margin-right: 30px;
  svg {
    color: $green;
    font-size: 50px;
    margin-right: 5px;
  }
  span {
    color: $black__default;
    font-size: 30px;
    margin-top: 6px;
  }
}

.register__successText {
  text-align: left;
  line-height: 25px;
  color: $gray__tip;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: $breakpoint-desktop) {
  .register__box {
    padding: 16px;
  }
  .register__steps {
    display: none;
  }
  .register__wrapperRight {
    clear: both;
    width: 100%;
    background-color: $white__default;
    height: auto;
    padding: 0 10px 30px 10px;
  }
  .register__wrapperLeft {
    clear: both;
    width: 100%;
    background-color: $blue__markup;
    color: $white__default;
    height: 230px;
    .register__contentInfo {
      float: none;
      position: unset;
      width: 100%;
      max-width: none;
      margin-top: 0;
    }
    .register__icon {
      margin-left: 50%;
      position: relative;
      left: -32px;
    }
    .register__headerTitle {
      width: 50%;
      text-align: center;
      padding: 5px 5% 0 5%;
    }
    .register__header {
      margin-top: 10px;
      width: 100%;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .steps__wrapper {
    display: none !important;
  }
  .register__headerTitle {
    width: 100% !important;
  }
}
