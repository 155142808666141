.questions {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.container__left {
	display: flex;
	flex-direction: column;
	background: #0B2C46;
}
.return__wrapper {
	width: 100px;
	margin: 20px 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: #fff;
	font-size: 1.2em;
	cursor: pointer;
}

.bottomLeft__wrapper {
	display: flex;
	justify-content: center;
}
.picture__wrapper {
	height: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #fff;
	padding: 10px 0;
	font-size: 2rem;
	text-align: center;
}
.chefImg {
	font-size: 100px;
}
.text {
	font-size: 1em;
	padding: 0 0 20px 0;
	color: #fff;
}
				
.superballs {
	display: none;
}
	
.box__lightBlue {
	background: #41c0f0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.questions__wrapper {
	width: 100%;
	margin: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.questions__title {
	color: $white__default;
	align-self: flex-start;
	font-size: 1.6em;
}

.radio-toolbar input[type="radio"] {
	opacity: 0;
	position: fixed;
	width: 0;
}

.radio-toolbar label {
	height: auto;
	margin: 20px 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	color: $white__default;
	background-color: #00ace2;
	font-size: 1.2em;
	text-align: justify;
}
.radio-toolbar label:hover {
color: #41c0f0;
background: transparent linear-gradient(283deg, #E8F1FA 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}
.radio-toolbar input[type="radio"]:focus + label {
border: 2px solid #fff;
}
.radio-toolbar input[type="radio"]:checked + label {
color: #41c0f0;
background: transparent linear-gradient(283deg, #E8F1FA 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}

.buttons__container  {
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.btn__return {
	height: 60px;
	width: 100%;
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	border-radius: 10px;
	color: $blue__area;
	border: 2px solid $white__default;
	background: $white__default 0% 0% no-repeat padding-box;
	box-shadow: 0px 12px 25px #00000029;
	font-size: 1.4rem;
} 
.btn__return:hover {
	border: $blue__markup;
	background-color: $blue__markup;
	color: $white__default
}

.btn__green {
	height: 60px;
	width: 100%;
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	border-radius: 10px;
	color: #fff;
	border: 2px solid #1FE390;
	background: #1FE390 0% 0% no-repeat padding-box;
	box-shadow: 0px 12px 25px #00000029;
	font-size: 1.4rem;
}
.btn__green:hover {
	background-color: #39b443;
	border: 2px solid #39b443;
	color: $white__default
}

@media only screen and (min-width: 960px) {
	.questions {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
	}
	.return__wrapper {
		display: flex;
		color: #fff;
		padding: 15px 0px 0px 15px;
		position: absolute;
		left: 0;
		top: 0;
	}

	.bottomLeft__wrapper {
		position: relative;
		height: calc(100vh - 89px);
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		justify-items: center;
	}
	.picture__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.chefImg {
		font-size: 180px;
	}
	.text {
		margin: 10px 0 0 0;
		font-size: 1.6rem;
		text-align: center;
		margin-left: 40px;
	}
	.superballs {
		width: 200px;
		height: 455px;
		display: flex;
		flex-direction: column;
		border-left: 1px solid #8596a3;
	}
}
