@import '../lib/variables.module';

.rbc-off-range-bg {
  background: #f5f5f5 !important;
}

.rbc-calendar {
  > div {
    &:last-of-type {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 15px 30px #00000029;
      border-radius: 20px;
      border: none !important;
      overflow: hidden;

      .rbc-allday-cell {
        display: none !important;
      }

      .rbc-header, .rbc-time-slot {
        padding: 3px 3px !important;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #777777;

        span {
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
          color: #777777;
        }
      }

      .rbc-time-slot {
        padding: inherit;
      }

      .rbc-date-cell {
        padding-right: 5px;
        padding-top: 5px;

        > a {
          font-size: 10px;
          color: #777777;
        }

        &.rbc-now {
          > a {
            padding: 5px;
            background: $blue__area;
            color: #FFFFFF;
            border-radius: 50%;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.rbc-time-header-cell {
  .rbc-header {
    border-bottom: none !important;
  }
}

.rbc-time-content {
  border-top: 1px solid #DDD !important;
}

.rbc-agenda-view table.rbc-agenda-table {
  border: none !important;
}

.rbc-toolbar {
  .rbc-btn-group:first-of-type, .rbc-btn-group:last-of-type {
    > button {
      border: 2px solid #808080 !important;
      border-radius: 12px !important;
      padding: 0 !important;
      width: 40px !important;
      height: 40px !important;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: none !important;

      &:hover {
        background: $blue__area !important;
        color: #FFFFFF !important;
        border: 2px solid $blue__area !important;
      }

      &:first-of-type {
        margin-right: 16px;
        padding: 0.375rem 1rem !important;
        width: auto !important;
        border-radius: 6px !important;
      }

      &:last-of-type {
        margin-left: 16px;
      }
    }
  }

  .rbc-btn-group:last-of-type {
    @media(max-width: 705px) {
      margin-top: 16px;
    }
    > button {
      width: auto !important;
      height: 40px !important;
      border-radius: 6px !important;
      margin: 0 4px;
      border: none !important;
      padding: 0.375rem 1rem !important;

      &:hover, &.rbc-active {
        background: $blue__area !important;
        color: #FFFFFF !important;
        border: none !important;
      }

      &:last-of-type {
        display: none !important;
      }

      &:first-of-type + button + button {
        margin-right: 0 !important;
      }
    }
  }
}

.rbc-toolbar-label {
  font-size: 30px !important;
  color: #000000 !important;
}

.scaleEvent {
  .rbc-event-content > div:first-of-type {
    font-size: 12px;
    display: flex;
    align-items: center;

    &::before {
      content: ' ';
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 6px;
      margin-right: 6px;
    }
  }

  .rbc-event-content > div:last-of-type {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    white-space: nowrap;

    &::before {
      flex: none;
      content: ' ';
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 6px;
      margin-right: 6px;
    }
  }
}