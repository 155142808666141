.vagasDados {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin: 20px 10px 0px 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 20px;
}

.return__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6em;
  margin: 10px 0px 60px 10px;
  cursor: pointer;
}

.return__title {
  padding-right: 25%;
}

.opportunity__data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.data__title {
  font-size: 1.6em;
  margin: 0 0 20px 0;
}

.opportunity__turn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0 0 0;
  border-bottom: 1px solid #707070;
  color: black;
  opacity: 0.5;
}

.turn__title {
  margin: 10px 0 0 0;
  font-size: 1em;
}

.turn__wrapper {
  display: flex;
  margin: 10px 0 0 0;
}
.turnSpace {
  width: 40px;
}
.opportunity__rest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 0 0;
  border-bottom: 1px solid #707070;
  color: black;
  opacity: 0.5;
}
.rest__title {
  margin: 10px 0 0 0;
  font-size: 1em;
}
.rest__wrapper {
  display: flex;
  margin: 10px 0 0 0;
}
.restSpace {
  width: 40px;
}
.opportunity__description {
  display: flex;
  flex-direction: column;
  justify-content:center;
  margin: 20px 0 0 0 ;
  color: black;
  opacity: 0.5;
}
.description__title {
  font-size: 1em;
  margin: 0 0 10px 0;
}
.description__box {
  display: flex;
  border-radius: 15px;
}
.opportunity__associate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0 0 0;
}
.associate__title {
  font-size: 1.6em;
  text-align: center;
  margin: 0 0 20px 0;
  text-align: start;
}

.radioToolbar__vacancyData {
	width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  column-gap: 20px;
  row-gap: 20px;
	}
	.radioToolbar__vacancyData input[type="radio"] {
	opacity: 0;
	position: fixed;
	width: 0;
	}
.radioToolbar__vacancyData label {
  height: 120px;
  width: 140px;
	display: flex;
  justify-content: center;
  align-items: center;
	flex-direction: column;
	border-radius: 10px;
	font-size: 1.2em;
  text-align: start;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #00000029;
  border-radius: 15px;
  opacity: 1;
}
.radioToolbar__vacancyData label:hover {
color: #fff;
background: transparent linear-gradient(283deg, #0b2c46 0%, #0b2c46 100%) 0% 0% no-repeat padding-box;
}
.radioToolbar__vacancyData input[type="radio"]:focus + label {
border: 2px solid #fff;
}
.radioToolbar__vacancyData input[type="radio"]:checked + label {
color: #fff;
background: transparent linear-gradient(283deg, #0b2c46 0%, #0b2c46 100%) 0% 0% no-repeat padding-box;
}
    
.createBtn__wrapper {
    display: flex;
    justify-content: center;
}
.opportunity__createBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 200px;
    height: 50px;
    margin-top: 40px;
    color: #fff;
    background-color: #41C0F0;
    border-radius: 10px;
    border: 2px solid #fff;
    font-weight: 700;
    font-size: 1em;
}

@media screen and (min-width: 800px){
    .vagasDados {
        padding: 40px 0px 20px 0px;
    }
    .return__wrapper {
        display: none;
    }
    .opportunity__data {
        width: 40%;
        margin: 0 0 0 30%;
    }

    .associate__title {
        text-align: center;
    }

    .associate__wrapper {
        width: 70%;
        margin-left: 15%;
    }
    .radioToolbar__vacancyData {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .radioToolbar__vacancyData label {
        height: 120px;
        width: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        font-size: 1.2em;
        text-align: start;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 15px 30px #00000029;
        border-radius: 15px;
        opacity: 1;
      }
}
